<template>
  <div class="sing-page-container" :class="{ 'layout-border': !isReviewPage }">
    <SingPage
      :singList="singList"
      :songInfo="songInfo"
      :timeStampList="timeStampList"
      :isReviewPage="isReviewPage"
    ></SingPage>
  </div>
</template>
<script>
import SingPage from "@/components/Course/CoursePage/SingPage";

export default {
  props: {
    isReviewPage: {
      type: Boolean,
    },
  },
  components: {
    SingPage,
  },
  data() {
    return {
      songInfo: {
        songUrl: require("@/assets/audio/songs/lesson-37.mp3"),
      },
      timeStampList: [1.8, 5.5, 9.3, 13.1, 17.0, 21.0, 25.1, 30.4],
      singList: {
        lyricsArr: [
          {
            id: 1,
            spelling: "Shéi de gèzi gāo? Wǒ de gèzi gāo.",
            chineseWords: "谁的个子高？我的个子高。",
          },
          {
            id: 2,
            spelling: "Gāo, gāo, gāo gāo gāo!",
            chineseWords: "高、高，高高高！",
          },
          {
            id: 3,
            spelling: "Shéi de shǒu dà? Bàba de shǒu dà.",
            chineseWords: "谁的手大？爸爸的手大。",
          },
          {
            id: 4,
            spelling: "Dà, dà, dà dà dà.",
            chineseWords: "大、大、大大大。",
          },
          {
            id: 5,
            spelling: "Wǒ yǒu māo. Tā yǒu gǒu.",
            chineseWords: "我有猫。他有狗。",
          },
          {
            id: 6,
            spelling: "Wǒ méiyǒu gǒu. Tā méiyǒu māo.",
            chineseWords: "我没有狗。他没有猫。",
          },
          {
            id: 7,
            spelling: "Wǒ méiyǒu gǒu. Tā méiyǒu māo.",
            chineseWords: "我没有狗。他没有猫。",
          },
        ],
      },
    };
  },
};
</script>
<style lang="scss" scoped>
.singpage-container {
  width: calc(100% - 20px);
  height: calc(100% - 40px);
}
</style>
